import './App.css';
import video from './hääloop.mp4'

function App() {

  return (
    <div>
      <div className='ContainerAlpha'>
        <p>Stefan & Roosa</p>
        <p> 21.09.2024</p>
      </div>

      <div className='border'></div>
      <header>
        <nav>
          <ul className="nav_links">
            <li id='item1'><a href='#Info' onClick={Toggler1}>Info</a></li>
            <li id='item2'><a href='#Aikataulu' onClick={Toggler2}>Aikataulu</a></li>
            <li id='item3'><a href='#Häätiimi' onClick={Toggler3}>Häätiimi</a></li>
          </ul>
        </nav>
      </header>
      <section id='inf' className='Infoosio'>
        <h1 className='Title'>Info</h1>
        <div className='sijantibox'>
          <div className='item'><p>Martin kirkko</p>
            <p>Huovikatu 1,20810 Turku</p>
            <p>Vihkiminen alkaa klo 14.45</p>
          </div>
          <div className='item'><p>Juhlava</p>
            <p>Kastarikatu 4, 20880 Turku</p>
            <p>Juhlatilaisuus alkaa klo 16.00</p>
          </div>
        </div>
        <div>
          <p className='infotext info1'>Vihkimisen jälkeen tarjoamme bussikuljetuksen <b>kaikille</b> kirkolta juhlatilaan. Bussi lähtee kirkolta klo 15.45</p>
          <br></br>
          <p className='infotext info2'>Vihkimisen sekä juhlatilaisuuden aikana paikalla on valokuvaaja. Pyydämmekin jättämään oman puhelimen
            tai kameran taskuun tai laukkuun.
            Toivomme että nautitte hetkestä kanssamme ettekä puhelimen näytön takaa. (plus kuvat näyttävät
            kivemmilta kun jokaisella ei ole puhelinta naaman edessä.)
          </p>
          <br></br>
          <p className='infotext info2'>Pukukoodi: Pue hienoimmat juhlavaatteet yllesi, vältäthän kuitenkin seuraavia värejä: tumma violetti ja
            valkoinen. Jos et ole varma sopiiko vaatetuksesi, voit aina ottaa yhteyttä Roosaan tai Stefaniin.
          </p>
          <br></br>
          <p className='infotext info1'>Häälahja:<br></br>
            Olemme todella kiitollisia läsnäolostanne häissämme. Koska meillä on ollut onni rakentaa yhdessä koti
            vuosien varrella, vastaanottaisimme mieluiten lahjat tilille, tai kirjekuoressa.
            Rahalahjat voi laittaa yhteiselle tilillemme FI93 6601 0010 6555 79<br></br>
            Tavaralahjat ja kirjekuoret voitte tuoda hääjuhlaan mukananne. </p>
        </div>
        <h1 className='Title2'>Parkkipaikat</h1>
        <div className='parkki'>
          <p>Niille jotka tulevat omalla autolla: </p>
          <p>Juhlavan edessä on kaksi paikkaa, joista toinen on varattu
            morsiusparille</p>
          <p>Parkkipaikkoja löytyy mm. Kastarikadulta (tienvarsipaikat),
            Rätiälänkadulta K-Market Ispoa vastapäätä, Puistomäen pallokentän
            läheisyydestä. </p>
        </div>
      </section>
      <section style={{ display: 'none' }} id='aika' className='Aikataulu'>
        <h1 className='Title'>Aikataulu</h1>
        <div className='parkki'>
          <p>Saavu kirkkoon n. 10-15 min ennen vihkimistä</p>
          <br></br>
          <p>Vihkiminen klo 14.45-15.30</p>
          <br></br>
          <p>Kuljetus Juhlavaan klo 15.45</p>
          <br></br>
          <p>Juhlatilaisuus alkaa klo 16</p>
          <br></br>
          <p>Ruokailu alkaa n. klo 16.30</p>
          <br></br>
          <p>Juhlatilaisuus päättyy klo 02</p>
        </div>
      </section>
      <section style={{ display: 'none' }} id='hää' className='Häätiimi'>
        <h1 className='Title'>Häätiimi</h1>
        <div className='fullboxi'>
          <div className='hääboxi'>
            <div className='hääteksti'>
              <p><b>Morsian</b></p>
              <p><b>Nimi:</b>Roosa</p>
              <p><b>Ikä:</b>25 v</p>
              <p><b>Horoskooppi:</b>Leijona</p>
              <p><b>Mitä teen vapaa-ajalla?</b> <br></br>Paljon juttuja, makoilen, katon telkkaria, joskus
                askartelen tai teen käsitöitä. Koitan keksiä miten<br></br>
                teen meidän kodista toimivamman ja ostan ehkä<br></br>
                joskus liikaa juttuja ikeasta..
              </p>
            </div>
            <br></br>
            <div className='hääteksti'>
              <p><b>Sulhanen</b></p>
              <p><b>Nimi:</b>Stefan</p>
              <p><b>Ikä:</b>26 v</p>
              <p><b>Horoskooppi:</b>Kalat</p>
              <p><b>Mitä teen vapaa-ajalla?</b> <br></br>Luen, harjoittelen kitaransoittoa, tykkään katsoa
                paljon elokuvia ja joskus kuvailen lyhytelokuvia.
              </p>
              <p><b>Miten tavattiin?</b> <br></br>Tutustuttiin tinderissä ja ensitreffit olikin Robert’s
                Coffee:ssa. Loppu onkin historiaa ;)
              </p>
            </div>
          </div>
          <div className='hääboxi2'>
            <div className='hääteksti2'>
              <p><b>Bestman</b></p>
              <p><b>Nimi:</b> Jami</p>
              <p><b>Ikä:</b>26 v</p>
              <p><b>Horoskooppi:</b> Härkä</p>
              <p><b>Mitä teen vapaa-ajalla?</b> <br></br>
                Paljon kaikkea, kuten musiikkin tuottamista!
              </p>
              <p><b>Miten tunnen morsiusparin?</b> <br></br>
                Stefanin kautta luokiossa.
              </p>
            </div>
            <div className='hääteksti2'>
              <p><b>Kaaso</b></p>
              <p><b>Nimi:</b>Emma</p>
              <p><b>Ikä:</b>24 v</p>
              <p><b>Horoskooppi:</b> Härkä</p>
              <p><b>Mitä teen vapaa-ajalla?</b> <br></br> Pyöritän perhearkea kahden lapsen äitinä ja etsin aikaa
                itselleni.
              </p>
              <p><b>Miten tunnen morsiusparin?</b> <br></br>
                Stefan on minun isoveljeni, ja hänen kautta pääsin
                tutustumaan <br></br> myös hänen upeaan tulevaan vaimoonsa.
              </p>
            </div>
            <div className='hääteksti2'>
              <p><b>Kaaso</b></p>
              <p><b>Nimi:</b>Emmi</p>
              <p><b>Ikä:</b>25 v</p>
              <p><b>Horoskooppi:</b>Leijona</p>
              <p><b>Mitä teen vapaa-ajalla?</b> <br></br> Olen kotona kissojeni kanssa tai tinder-treffeillä
              </p>
              <p><b>Miten tunnen morsiusparin?</b> <br></br>
                Tutustuttiin Roosan kanssa seiskaluokalla.
              </p>
            </div>
          </div>
        </div>
      </section >
    </div >
  );
}

function Toggler1() {
  var x = document.getElementById("inf");
  var y = document.getElementById("aika");
  var z = document.getElementById("hää");
  if (x.style.display === "none") {
    x.style.display = "block";
    y.style.display = "none";
    z.style.display = "none";
  }
  else {
    x.style.display = "none";
  }
}
function Toggler2() {
  var x = document.getElementById("aika");
  var y = document.getElementById("inf");
  var z = document.getElementById("hää");
  if (x.style.display === "none") {
    x.style.display = "block";
    y.style.display = "none";
    z.style.display = "none";
  }
  else {
    x.style.display = "none";
  }

}
function Toggler3() {
  var x = document.getElementById("hää");
  var y = document.getElementById("inf");
  var z = document.getElementById("aika");
  if (x.style.display === "none") {
    x.style.display = "block";
    y.style.display = "none";
    z.style.display = "none";
  }
  else {
    x.style.display = "none";
  }
}
export default App;
